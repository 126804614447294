<template>
  <div class="uploadedImage">
    <img :src="url" :alt="altText" />
  </div>
</template>

<script>
import { computed } from "vue";

export default {
  name: "UploadedImage",
  props: {
    url: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const altText = computed(() => {
      const imageName = props.url.split("/").pop();
      return `My uploaded image named '${imageName}'`;
    });
    return {
      altText,
    };
  },
};
</script>

<style scoped></style>
