<template>
  <div class="imageUrlInput relative">
    <!--    <label for="imageUrl">Image Url</label>-->
    <div class="flex items-center gap-2">
      <TextInput
        :tabIndex="2"
        class="w-4 8 hidden"
        :value="modelValue ?? defaultValue"
        name="imageUrl"
        placeholder="Image Url"
        @input="onInput"
        @change="onChange"
      ></TextInput>
      <Button class="py-1" :isAlt="true" @click="showImagePicker = true">
        <template #left>
          <IconCloudUploadOutline class="pr-2"></IconCloudUploadOutline>
        </template>
        <template #center>{{ label || "Select Image" }}</template>
      </Button>
    </div>
    <div
      v-if="showImagePicker"
      class="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 w-[80vw] k15:w-[40vw] h-[70vh] bg-white z-40 shadow-xl"
    >
      <UniqueImagePicker
        v-if="isUniqueImage"
        @close="showImagePicker = false"
        @select="onChange"
      ></UniqueImagePicker>
      <ImagePicker
        v-else
        class=""
        :show-library="showLibrary"
        @close="showImagePicker = false"
        @select="onChange"
      ></ImagePicker>
    </div>
  </div>
</template>

<script>
import IconCloudUploadOutline from "@/components/icons/CloudUploadOutline";
import ImagePicker from "@/components/image/ImagePicker";
import UniqueImagePicker from "@/components/image/UniqueImagePicker.vue";
import TextInput from "@/components/forms/TextInput";
import Button from "@/components/forms/Button";
import { onBeforeUnmount, onMounted, ref } from "vue";

export default {
  name: "ImageUrlInput",
  emits: ["change", "input"],
  components: {
    Button,
    IconCloudUploadOutline,
    ImagePicker,
    TextInput,
    UniqueImagePicker,
  },
  props: {
    modelValue: {
      type: String,
      default: "",
    },
    defaultValue: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "Select Image",
    },
    isUniqueImage: {
      type: Boolean,
      default: false,
    },
    showLibrary: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, { emit }) {
    const showImagePicker = ref(false);
    const show = ref(false);

    const onInput = (value) => emit("input", value);
    const onChange = (value) => {
      emit("change", value);
      showImagePicker.value = false;
    };

    const closeDropdown = () => {
      showImagePicker.value = false;
    };

    // Add onClickOutside method
    const onClickOutside = (event) => {
      const dropdownElement = event.target.closest(".imageUrlInput.relative");
      if (!dropdownElement) {
        closeDropdown();
      }
    };

    // Add and remove the onClickOutside listener
    onMounted(() => {
      document.addEventListener("click", onClickOutside);
    });

    onBeforeUnmount(() => {
      document.removeEventListener("click", onClickOutside);
    });
    return {
      onChange,
      onInput,
      showImagePicker,
      show,
    };
  },
};
</script>

<style scoped></style>
