<template>
  <div class="imagePicker h-full">
    <LTabs
        :labels="tabLabels"
        :selected="selectedTab"
        :update="refreshTabs"
    >
      <template #one>
          <UploadImage
                  class="min-w-[40vw]"
                  :message="message"
                  @uploaded="onUploaded"
          ></UploadImage>
      </template>

      <template #two>
          <div class="w-full p-4 flex flex-col justify-center gap-y-8">
              <Button
                      class="button"
                      :isAlt="true"
                      @click="onSelect(defaultImageUrl)"
              >
                  <template #center>Use Default Image</template>
              </Button>

              <div>
                  <span>External Image Url</span>
                  <TextInput
                          placeholder="External Image Url"
                          hint="An image url is required"
                          :value="value"
                          icon="imageFrame"
                          @change="previewExternalUrl = true"
                          @input="onRemoteUrl"
                  ></TextInput>
                  <div class="flex justify-center">
                      <img
                              class="m-4 max-h-[200px] max-w-[200px]"
                              v-if="previewExternalUrl"
                              :src="previewUrl"
                              alt="preview image"
                              @load="onSelect(previewUrl)"
                      />
                  </div>
              </div>
          </div>
      </template>

    </LTabs>
  </div>
</template>

<script>
import {onMounted, onUnmounted, ref, watch} from "vue";
import LTabs from "../layout/LTabs";
import TextInput from "../forms/TextInput";
import UploadImage from "./UploadImage";
import {useRouter} from "vue-router";
import Button from "../forms/Button";

export default {
  name: "ImagePicker",
  emits: ["select", "hide"],
  components: {
    Button,
    LTabs,
    TextInput,
    UploadImage,
  },
  props: {
    value: {
      type: String,
      default: "/placeholder.jpg",
    },
    message: {
      type: String,
      default: "",
    },
    defaultImageUrl: {
      type: String,
      default: "/placeholder.jpg",
    },
    types: {
      type: Object,
      default: () => ({
        types: ["one", "two", "three"],
      }),
    },
  },
  setup(props, {emit}) {
    const router = useRouter();
    const loadingImages = ref(true);
    const previewExternalUrl = ref(false);
    const previewUrl = ref("");
    const refreshTabs = ref(0);
    const selectedTab = ref(0);

    const tabLabels = ['Upload', 'Custom']

    const handleKey = (e) => {
      if (e.keyCode === 27) hideImagePicker();
    };

    onMounted(() => document.addEventListener("keyup", handleKey));
    onUnmounted(() => document.removeEventListener("keyup", handleKey));

    const onUploaded = (files) => {
      onSelect(files[0])
    };

    const hideImagePicker = () => emit("hide");

    const onSelect = (image) => {
      emit("select", image);
      emit('hide')
      hideImagePicker();
    };

    const onRemoteUrl = (value, done) => {
      previewUrl.value = value.trim();
      if (done) previewExternalUrl.value = true;
    };
    watch(router.currentRoute, hideImagePicker);

    return {
      loadingImages,
      previewExternalUrl,
      onRemoteUrl,
      onSelect,
      onUploaded,
      previewUrl,
      refreshTabs,
      selectedTab,
      tabLabels,
    };
  },
};
</script>

<style scoped></style>
