<template>
  <div class="uploadImage h-full">
    <div class="extraOutline p-4 w-full m-auto rounded-lg">
      <div
          v-if="messageText"
          class="message"
      >
        {{ messageText }}
      </div>
      <div
          class="file_upload p-5 relative border-4 border-dotted border-gray-300 rounded-lg"
      >
        <svg class="text-indigo-500 w-24 mx-auto mb-4" xmlns="http://www.w3.org/2000/svg" fill="none"
             viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"/>
        </svg>
        <div class="input_field flex flex-col w-max mx-auto text-center">
          <label>
            <input
                class="text-sm cursor-pointer w-36 hidden"
                type="file"
                accept="image/*"
                multiple
                @change="onUpload"
            />
            <div
                class="text bg-indigo-600 text-white border border-gray-300 rounded font-semibold cursor-pointer p-1 px-3 hover:bg-indigo-500"
            >
              Select
            </div>
          </label>
          <div class="title text-indigo-500 uppercase">or drop files here</div>
        </div>
        <ProgressBar
            v-if="progress"
            :value="progress"
        ></ProgressBar>
        <p
            v-if="errorMessage"
            class="message"
        >
          {{ errorMessage }}
        </p>

      </div>
    </div>
  </div>
</template>

<script>
import {inject, ref} from "vue";
import ProgressBar from "../forms/ProgressBar";

export default {
  name: "UploadImage",
  emits: ['uploaded'],
  components: {
    ProgressBar,
  },
  props: {
    message: {
      type: String,
      default: ''
    }
  },
  setup(props, {emit}) {
    const axios = inject('AXIOS')
    const images = ref([])

    const progress = ref(0)
    const messageText = ref(props.message)
    const errorMessage = ref('')

    async function uploadFiles(formData) {
      const onUploadProgress = event => progress.value = Math.round((100 * event.loaded) / event.total)

      const handleErrorResponse = response => {
        const {error} = response
        if (error) {
          console.log(77, response, error)
          errorMessage.value = error
        }
      }
      const handleFilesResponse = response => {
        const {files, message} = response.data
        if (files) images.value = files
        if (message) messageText.value = message
      }

      progress.value = 1
      messageText.value = ''
      errorMessage.value = ''
      try {
        const response = await axios.post(
            `/upload/unique/`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              onUploadProgress,
            }
        )
        handleErrorResponse(response)
        handleFilesResponse(response)
      } catch (error) {
        if (error.response) { // get response with a status code not in range 2xx
          console.log(833.6, error.response.data)
          console.log(833.5, error.response.status)
          console.log(833.4, error.response.headers)
        } else if (error.request) { // no response
          console.log(833.3, error.request)
        } else { // Something wrong in setting up the request
          console.log(833.2, 'Error', error.message)
        }
      }
      progress.value = 0
      emit('uploaded', images.value)
    }

    const getImageUploadFormdata = files => {
      /*
        FileList {
          lastModified,
          name,
          size,
          type,
          webkitRelativePath
        }
       */
      let formData;
      if (files?.length) {
        formData = new FormData()
        for (const file in files) {
          formData.append("files", files[file])
        }
      }
      return formData
    }

    const onUpload = e => {
      let files = e.target.files
      const formData = getImageUploadFormdata(files)

      if (formData) {
        uploadFiles(formData)
      }
    }
    return {
      errorMessage,
      messageText,
      onUpload,
      progress,
    }
  }
}
</script>

<style scoped>

</style>
